.header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}

body {
  background-color:#CECBCA;
  border-radius: 25px;
  align-items: center;
  justify-content: center;
}

@keyframes cf-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form {
  background: #fff;
  border-radius: 25px;
  padding-top: 30px;
  padding-bottom: 30px;
  position: absolute;
  border-top: 5px solid #a9aaa9;
}

::-webkit-input-placeholder {
  font-size: 1.3em;
}


.comma-separated-input {
  background-color: #ebebeb;
  overflow: hidden;
  width: 300px;
  height: 10rem;
  border-radius: 25px;
}

.comma-separated-input:hover {
  border-bottom: 5px solid #3077e9;
  height: 12em;
  width: 300px;
  transition: ease 0.5s;
  border-radius: 25px;

}

.input {
  background-color: #ebebeb;
  width: 300px;
  height: 2em;
}

table, th, td {
  border: 2px solid black;
}

.input:hover {
  border-bottom: 5px solid #3077e9;
  height: 30px;
  width: 300px;
  transition: ease 0.5s;
}

.form-entry {
  display: block;
  margin: 30px;
  width: 300px;
  padding: 10px;
  border-radius: 25px;
  border: none;
  transition: all 0.5s ease 0s;
}

.textLabel {
  color: #282c34;
  padding-left: 20px;
  margin: auto;
}

.submit {
  width: 300px;
  color: white;
  background-color: #3077e9;
  border-radius: 25px;
  font-size: 20px;
  align-content: center;
}

.submit:hover {
  box-shadow: 15px 15px 15px 5px rgba(78, 72, 77, 0.219);
  transform: translateY(-3px);
  width: 300px;
  background-color: #1456DF;
  border-radius: 10px;
  transform: scale(1.1);
} 

.submit:disabled{
  border: 1px solid #c0bfc8;
  background-color: #c0bfc8;
}